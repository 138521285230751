import router, { useRouter } from 'next/router';
import Head from 'next/head';
import { Source } from '../components'
import { useEffect } from 'react';
export async function getServerSideProps({ query }) {
  const club = query.club || ""
  const code = query.code || ""
  return {
    props: {
      club, code
    }
  }
}

export default function Club({club, code}) {
  const router = useRouter()
  useEffect(() => {
    router.push(`/?club=${club}&code=${code}`)
  }, [club, code])
  return (
    <div className="">
      <Head>
        <title>John Jay Attendance Portal</title>
        <link href="/main.css" rel="stylesheet" type="text/css" />
        <Source />
      </Head>
      <div className="relative dark:bg-gray-900 min-h-screen min-w-screen">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="flex max-w-sm mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-4xl xl:max-w-6xl">
            <div className="w-full px-6 py-8 md:px-8">
              <h2 className="text-2xl font-semibold text-center text-gray-700 dark:text-white">Redirecting</h2>
              <p className="text-lg text-center text-gray-600 dark:text-gray-200">This should only take a moment....</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}